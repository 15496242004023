<template>
  <!-- 工作台 -->
  <div class="workbench">
    <!-- 工作查询 -->
    <el-form
      :inline="true"
      label-width="auto"
      :model="form"
      class="demo-form-inline"
    >
      <el-form-item label="职位">
        <el-select
          size="small"
          v-model="form.job"
          @change="getGroups"
          placeholder="请选择"
        >
          <el-option
            v-for="item in jobOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组别">
        <el-select
          size="small"
          @change="getPersons"
          v-model="form.group"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in groupOptions"
            :key="item.keyy"
            :label="item.valuee"
            :value="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员">
        <el-select size="small" v-model="person" placeholder="请选择">
          <el-option
            v-for="item in personOptions"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="form.timeList"
          size="small"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          @change="checkDate"
          style="width: 234px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select
          size="small"
          v-model="form.channel"
          @change="checkChannel"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="马甲">
        <el-select
          size="small"
          v-model="form.vest"
          placeholder="请选择"
          @change="checkVest"
        >
          <el-option
            v-for="item in vestOPtions"
            :key="item.keyy"
            :label="item.valuee"
            :value="item.keyy"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- <div class="clear" @click="clear">清空</div> -->
        <el-button size="mini" type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 待处理 -->
    <div class="handle">
      <!-- 待处理订单 -->
      <div class="hand order">
        <div class="head pd flex">
          <div class="big">
            待处理订单(<span class="num">{{
              workOrderEventStatisticsVoObj.pendingOrders
            }}</span
            >)
          </div>
          <div class="btn">
            <el-button
              size="mini"
              :class="btnCur == index ? 'active' : ''"
              v-for="(item, index) in btn"
              :key="index"
              @click="btnFn(index)"
              >{{ item.name }}{{ item.num }}</el-button
            >
          </div>
          <div @click="gotoOrder" class="all">全部 ></div>
        </div>
        <div class="orderContent">
          <div
            class="content pd"
            v-for="(item, index) in workBenOrderVoLIst"
            :key="index"
          >
            <div class="hotelName flex">
              <div class="hotel">
                <div class="icon"></div>
                {{
                  item.hotelName == ""
                    ? "未查询到酒店名称"
                    : item.hotelName == null
                    ? "未查询到酒店名称"
                    : item.hotelName
                }}
              </div>
              <el-tag size="small">{{
                item.platform == null
                  ? "未知渠道"
                  : item.platform == ""
                  ? "未知渠道"
                  : item.platform
              }}</el-tag>
              <div class="phone">
                {{
                  item.orderSum == null
                    ? "暂未查询到订单号"
                    : item.orderSum == ""
                    ? "暂未查询到订单号"
                    : item.orderSum
                }}
              </div>
              <div class="status">
                未处理/<span>{{
                  item.payStatus == null
                    ? "未知状态"
                    : item.payStatus == ""
                    ? "未知状态"
                    : item.payStatus
                }}</span>
              </div>
            </div>
            <div class="msg pd">
              <el-tag size="small">{{
                item.rateCategory == "1" ? "预付" : item.rateCategory
              }}</el-tag>
              <div class="room">
                {{ item.layoutOfHouse }}
              </div>
            </div>
            <div class="time pd">
              {{
                item.checkIn == null
                  ? "未知入住时间"
                  : item.checkIn == ""
                  ? "未知入住时间"
                  : item.checkIn
              }}
              <div class="long">
                —
                <div>
                  {{
                    item.fewNight == null
                      ? "0"
                      : item.fewNight == ""
                      ? "0"
                      : item.fewNight
                  }}晚
                </div>
                —
              </div>
              {{
                item.checkOut == null
                  ? "未知离店时间"
                  : item.checkOut == ""
                  ? "未知离店时间"
                  : item.checkOut
              }}
            </div>
            <div class="user flex">
              <span>{{
                item.guestName == null
                  ? "匿名"
                  : item.guestName == ""
                  ? "匿名"
                  : item.guestName
              }}</span>
              <span>{{
                item.orderCreateTime == null
                  ? "暂未查询到订单创建时间"
                  : item.orderCreateTime == ""
                  ? "暂未查询到订单创建时间"
                  : item.orderCreateTime
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 待处理事件 -->
      <div class="hand thing">
        <div class="head pd flex">
          <div class="big">
            待处理事件(<span class="num">{{
              workOrderEventStatisticsVoObj.pendingEvents
            }}</span
            >)
          </div>
          <div class="btn">
            <el-button
              size="mini"
              :class="btnCurThing == index ? 'active' : ''"
              v-for="(item, index) in btnCurThingList"
              :key="index"
              @click="btnCurThingFn(index)"
              >{{ item.name }}{{ item.num }}</el-button
            >
          </div>
          <div @click="gotoOrderFour" class="all">全部 ></div>
        </div>
        <div class="orderContent">
          <div
            class="thing pd"
            v-for="(item, index) in workBenEventVoLIst"
            :key="index"
          >
            <div class="left">
              <div class="wrap">
                <div class="info">订单号</div>
                <div class="infoText">
                  {{
                    item.orderNum == null
                      ? "暂未查询到订单号"
                      : item.orderNum == ""
                      ? "暂未查询到订单号"
                      : item.orderNum
                  }}
                </div>
              </div>
              <div class="wrap">
                <div class="info">酒店名称</div>
                <div class="infoText">
                  {{
                    item.hotelName == null
                      ? "暂未查询到酒店名称"
                      : item.hotelName == ""
                      ? "暂未查询到酒店名称"
                      : item.hotelName
                  }}
                </div>
              </div>
              <div class="wrap">
                <div class="info">类型</div>
                <div class="infoText">
                  {{
                    item.orderType == null
                      ? "暂未查询到订单类型"
                      : item.orderType == ""
                      ? "暂未查询到订单类型"
                      : item.orderType
                  }}
                </div>
              </div>
              <div class="wrap">
                <div class="info">创建时间</div>
                <div class="infoText">
                  {{
                    item.eventCreate == null
                      ? "暂未查询到订单创建时间"
                      : item.eventCreate == ""
                      ? "暂未查询到订单创建时间"
                      : item.eventCreate
                  }}
                </div>
              </div>
            </div>
            <div @click="gotoOrderFourForId(item.orderNum)" class="right">
              立即处理
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 总览 -->
    <div class="overview">
      <div class="top">总览</div>
      <div class="wrap">
        <div class="item">
          <div class="itemTop">
            <div class="itemTopLeft">夜间量</div>
          </div>
          <div class="itemBottom">
            {{
              allOverviewVoObj.nightSum == null
                ? "0"
                : allOverviewVoObj.nightSum == ""
                ? "0"
                : allOverviewVoObj.nightSum
            }}
          </div>
        </div>
        <div class="item">
          <div class="itemTop">
            <div class="itemTopLeft">销售额(CNY)</div>
          </div>
          <div class="itemBottom">
            {{
              allOverviewVoObj.salesSum == null
                ? "0.00"
                : allOverviewVoObj.salesSum == ""
                ? "0.00"
                : allOverviewVoObj.salesSum
            }}
          </div>
        </div>
        <div class="item">
          <div class="itemTop">
            <div class="itemTopLeft">平均房价</div>
          </div>
          <div class="itemBottom">
            {{
              allOverviewVoObj.averageHousePrice == null
                ? "0.00"
                : allOverviewVoObj.averageHousePrice == ""
                ? "0.00"
                : allOverviewVoObj.averageHousePrice
            }}
          </div>
        </div>
        <div class="item">
          <div class="itemTop">
            <div class="itemTopLeft">业绩</div>
          </div>
          <div class="itemBottom">
            {{
              allOverviewVoObj.achievement == null
                ? "0.00"
                : allOverviewVoObj.achievement == ""
                ? "0.00"
                : allOverviewVoObj.achievement
            }}
          </div>
        </div>
        <div class="item">
          <div class="itemTop">
            <div class="itemTopLeft">确认前推翻率</div>
          </div>
          <div class="itemBottom">
            {{
              allOverviewVoObj.turnoverRateBefore == null
                ? "0.0%"
                : allOverviewVoObj.turnoverRateBefore == ""
                ? "0.0%"
                : allOverviewVoObj.turnoverRateBefore
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- echarts -->
    <div class="chart">
      <div class="chartStar" id="starChart"></div>
      <div class="chartChannel" id="channelChart"></div>
    </div>
    <!-- table -->
    <div class="tableData">
      <div class="top">
        <el-row>
          <el-col :span="12">
            <div class="first">
              <el-button
                v-if="roleWorkbenchSales"
                plain
                :class="tableBtnCur == 0 ? 'tableBtn active' : 'tableBtn'"
                size="mini"
                @click="changeTable(0)"
                >销售</el-button
              >
              <el-button
                v-if="roleWorkbenchOperate"
                plain
                :class="tableBtnCur == 1 ? 'tableBtn active' : 'tableBtn'"
                size="mini"
                @click="changeTable(1)"
                >运营</el-button
              >
            </div>
          </el-col>
          <el-col :span="12" style="text-align: right"> </el-col>
        </el-row>

        <el-form
          :inline="true"
          label-width="auto"
          :model="tableSearchDate"
          class="demo-form-inline"
        >
          <el-form-item>
            <el-select size="small" v-model="tableSearchDateType">
              <el-option
                v-for="item in tableDateTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="tableTimeList"
              size="small"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="checkTableDate"
              style="width: 240px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="组别">
            <el-select
              size="small"
              v-model="tableSearchDate.goupId"
              @change="changeGroup"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in tableGroupOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="item.keyy"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人员">
            <el-select
              size="small"
              @change="getTableSearchPeople"
              v-model="tableSearchDate.people"
              placeholder="请选择"
            >
              <el-option
                v-for="item in tablePersonOptions"
                :key="item.keyy"
                :label="item.valuee"
                :value="item.keyy"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="tableSearch"
              >查询</el-button
            >
            <el-button v-if="roleWorkbenchExport" size="mini" type="primary">
              <a
                :href="Url"
                size="mini"
                type=""
                style="color: #256ef1"
                @click="exportData"
                >导出</a
              >
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="roleWorkbenchSettarget"
              type="text"
              @click="openTargetDia"
              >设置目标值</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" @sort-change="sortTable">
        <el-table-column
          prop="orderName"
          label="组别"
          width="100px"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="" label="携程业绩间夜量" width="80px">
          <template slot-scope="scope">
            <div>{{ scope.row.ctripPerformance }}</div>
            <div>{{ scope.row.ctripNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column label="艺龙业绩间夜量" width="80px">
          <template slot-scope="scope">
            <div>{{ scope.row.yiLongPerformance }}</div>
            <div>{{ scope.row.yiLongNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column label="去哪儿业绩间夜量" width="90px">
          <template slot-scope="scope">
            <div>{{ scope.row.goWherePerformance }}</div>
            <div>{{ scope.row.goWhereNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column label="美团业绩间夜量" width="80px">
          <template slot-scope="scope">
            <div>{{ scope.row.meiTuanPerformance }}</div>
            <div>{{ scope.row.meiTuanNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column label="飞猪业绩间夜量" width="80px">
          <template slot-scope="scope">
            <div>{{ scope.row.flyPigPerformance }}</div>
            <div>{{ scope.row.flyPigNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="总业绩 间夜量"
          width="80px"
          prop="totalPerformance"
          sortable="custom"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.totalPerformance }}</div>
            <div>{{ scope.row.totalNightTraffic }}</div>
          </template>
        </el-table-column>
        <el-table-column label="拒单率">
          <template slot-scope="scope">
            <div>{{ scope.row.rejectionRate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="利润率">
          <template slot-scope="scope">
            <div>{{ scope.row.profitMargin }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tableBtnCur == 1"
          label="夜间量目标值 业绩目标值 预订率目标值"
          width="120px"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.targetNightValue }}</div>
            <div>{{ scope.row.targetValue }}</div>
            <div>{{ scope.row.targetFullRoomValue }}</div>
          </template>
        </el-table-column>

        <el-table-column label="目标值" v-if="tableBtnCur == 0">
          <template slot-scope="scope">
            <div>{{ scope.row.targetValue }}</div>
          </template>
        </el-table-column>
        <el-table-column label="目标差额" v-if="tableBtnCur == 0">
          <template slot-scope="scope">
            <div>{{ scope.row.targetDiffValue }}</div>
          </template>
        </el-table-column>

        <el-table-column v-if="tableBtnCur == 1" label="目标差额" width="80px">
          <template slot-scope="scope">
            <div>{{ scope.row.targetNightDiffValue }}</div>
            <div>{{ scope.row.targetDiffValue }}</div>
            <div>{{ scope.row.targetFullRoomDiffValue }}</div>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.row.getSaleDataOneVOList"
              style="width: 100%"
            >
              <el-table-column prop="orderName" label="人员"></el-table-column>
              <el-table-column prop="" label="携程业绩间夜量" width="80px">
                <template slot-scope="scope">
                  <div>{{ scope.row.ctripPerformance }}</div>
                  <div>{{ scope.row.ctripNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="艺龙业绩间夜量" width="80px">
                <template slot-scope="scope">
                  <div>{{ scope.row.yiLongPerformance }}</div>
                  <div>{{ scope.row.yiLongNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="去哪儿业绩间夜量" width="90px">
                <template slot-scope="scope">
                  <div>{{ scope.row.goWherePerformance }}</div>
                  <div>{{ scope.row.goWhereNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="美团业绩间夜量" width="80px">
                <template slot-scope="scope">
                  <div>{{ scope.row.meiTuanPerformance }}</div>
                  <div>{{ scope.row.meiTuanNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="飞猪业绩间夜量" width="80px">
                <template slot-scope="scope">
                  <div>{{ scope.row.flyPigPerformance }}</div>
                  <div>{{ scope.row.flyPigNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="总业绩 间夜量" width="80px">
                <template slot-scope="scope">
                  <div>{{ scope.row.totalPerformance }}</div>
                  <div>{{ scope.row.totalNightTraffic }}</div>
                </template>
              </el-table-column>
              <el-table-column label="拒单率">
                <template slot-scope="scope">
                  <div>{{ scope.row.rejectionRate }}</div>
                </template>
              </el-table-column>
              <el-table-column label="利润率">
                <template slot-scope="scope">
                  <div>{{ scope.row.profitMargin }}</div>
                </template>
              </el-table-column>
              <el-table-column label="目标值" v-if="tableBtnCur == 0">
                <template slot-scope="scope">
                  <div>{{ scope.row.targetValue }}</div>
                </template>
              </el-table-column>
              <el-table-column label="目标差额" v-if="tableBtnCur == 0">
                <template slot-scope="scope">
                  <div>{{ scope.row.targetDiffValue }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tableBtnCur == 1"
                label="夜间量目标值 业绩目标值 预订率目标值"
                width="120px"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.targetNightValue }}</div>
                  <div>{{ scope.row.targetValue }}</div>
                  <div>{{ scope.row.targetFullRoomValue }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tableBtnCur == 1"
                label="目标差额"
                width="80px"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.targetNightDiffValue }}</div>
                  <div>{{ scope.row.targetDiffValue }}</div>
                  <div>{{ scope.row.targetFullRoomDiffValue }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="操作" width="200px">
          <el-button size="mini" type="primary">展开</el-button>
          <el-button size="mini" type="primary">收起</el-button>
        </el-table-column> -->
            </el-table>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="200px">
          <el-button size="mini" type="primary">展开</el-button>
          <el-button size="mini" type="primary">收起</el-button>
        </el-table-column> -->
      </el-table>
    </div>
    <SetSalesTarget ref="setsalestarget"></SetSalesTarget>
    <SetOperateTarget ref="setoperatetarget"></SetOperateTarget>
  </div>
</template>

<script>
import {
  getVestid_api,
  getGroups_api,
  getSaleDataOne_api,
  getOperateDataOne_api,
} from "../../apis/workbench";
import SetSalesTarget from "../../components/dialog/workbench/SetSalesTarget.vue";
import SetOperateTarget from "../../components/dialog/workbench/SetOperateTarget.vue";
import { getRoleKey } from "../../utils/rolekey";
export default {
  components: {
    SetSalesTarget,
    SetOperateTarget,
  },
  name: "hello",
  data() {
    return {
      roleWorkbenchSales: getRoleKey("role_workbench_sales"),
      roleWorkbenchOperate: getRoleKey("role_workbench_operate"),
      roleWorkbenchExport: getRoleKey("role_workbench_export"),
      roleWorkbenchSettarget: getRoleKey("role_workbench_settarget"),

      msg: "Welcome to Your Vue.js App", // 图表
      //工作查询表单
      form: {
        job: "", // 职位
        group: "", // 组别
        person: "", // 人员
        timeList: "", // 时间区间数组
        channel: "", // 渠道
        vest: "", // 马甲
      },

      person: "",
      ajaxVestId: "", // 接口请求vest的id
      ajaxGroupId: "", // 接口请求group的id
      ajaxPersonId: "", // 接口请求person的id
      allOverviewVoObj: "", // 总览(OBject)
      workBenOrderVoLIst: [], // 待处理订单(Array)
      workBenEventVoLIst: [], // 待处理事件(Array)
      workOrderEventStatisticsVoObj: "", // 待处理数据总汇(OBject)
      starLevelArray: [], // 星级分布(echarts存储数据数组)
      saleChannelArray: [], // 销售渠道(echarts存储数据数组)
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      }, //日期选择
      groupOptions: [], // 组别选择
      personOptions: [], // 人员选择
      vestOPtions: [], // 马甲选择
      jobOptions: [
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
        { label: "售前", value: 2, disabled: true },
        { label: "售后", value: 3, disabled: true },
        { label: "二次确认", value: 4, disabled: true },
      ], // 职位选择
      channelOptions: [
        { label: "艺龙", value: 1 },
        { label: "飞猪", value: 2 },
        { label: "美团", value: 3 },
        { label: "去哪儿", value: 4 },
        { label: "携程", value: 5 },
      ], // 渠道选择
      btnCur: "0", //选项按钮(待处理订单)
      btnCurThing: "0", // 选项按钮(待处理事件)
      btn: [
        { name: "今日新订", num: "0" },
        { name: "今日入住", num: "0" },
      ], // 待处理订单选项卡数据列表
      btnCurThingList: [
        { name: "客诉订单", num: "0" },
        { name: "待下线酒店", num: "" },
      ], // 待处理事件选项卡数据列表
      tableBtnLIst: [
        { name: "销售", id: 0 },
        { name: "运营", id: 1 },
      ], // 表格按钮数组数据
      tableBtnCur: "0", // 表格按钮初始值

      tableData: [
        {
          number: 1,
          orderName: "一组", //销售组别

          ctripPerformance: "1", // 携程业绩
          ctripNightTraffic: "2", // 携程夜间量
          yiLongPerformance: "1", // 艺龙业绩
          yiLongNightTraffic: "1", // 艺龙夜间量
          goWherePerformance: "2", // 去哪儿业绩
          goWhereNightTraffic: "2", // 去哪儿夜间量
          meiTuanPerformance: "4", // 美团业绩
          meiTuanNightTraffic: "4", // 美团夜间量
          flyPigPerformance: "5", // 飞猪业绩
          flyPigNightTraffic: "5", // 飞猪夜间量
          totalPerformance: "6", // 总业绩量
          totalNightTraffic: "6", // 总夜间量
          rejectionRate: "66%", // 拒单率
          profitMargin: "22%", // 利润率
          targetValue: "123", // 目标值
          targetDiffValue: "342", // 目标差值
          targetNightValue: "5443", // 夜间目标值-运营特有
          targetNightDiffValue: "45%", // 夜间目标值差值-运营特有
          targetFullRoomValue: "5476%", // 拒单目标值(确认前满房率目标值)-运营特有
          targetFullRoomDiffValue: "6454", // 拒单目标差值(确认前满房率目标值)-运营特有
        },
      ], // 表格数据
      //lg数据
      //表格请求参数
      tableSearchDate: {
        dateType: 1,
        startDate: "",
        endDate: "",
        goupId: "",
        people: "",
        iSall: 1,
        sortType: "",
        orderDirection: "asc",
      },
      Url: "",
      tableTimeList: [], // 表格时间显示
      tableGroupOptions: [], //列表头的组别
      tablePersonOptions: [], //；列表人员list
      tableSearchDateType: "所有日期",
      tableDateTypeOption: [
        { label: "所有日期", value: 1 },
        { label: "下单日期", value: 2 },
        { label: "入住日期", value: 3 },
        { label: "离店日期", value: 4 },
      ],
    };
  },
  created() {
    
   //初始每个月1号到最后一天
    // var startDate = this.getCurrentMonthFirst();
    // var endDate = this.getCurrentMonthLast();
    // this.form.timeList = [startDate, endDate];
    // this.tableTimeList = [startDate, endDate];

    //初始26号到下个月25号
      this.form.timeList =this.getDetailDate()
     this.tableTimeList = this.getDetailDate()
  
    this.ajaxVestId = "";
    this.ajaxGroupId = "";
    this.ajaxPersonId = "";
    this.getWorkbenchHeaderDataFn(
      "",
      "",
      "",
      "",
      this.form.timeList[0],
      this.form.timeList[1],
      "",
      ""
    ); // 初始化调用获取工作台头部分列表数据
    this.getTableOption(0);
    this.getTableList();
  },
  methods: {
    // 获取工作台头部数据功能函数
    getWorkbenchHeaderDataFn(
      rows,
      roleName,
      goupId,
      people,
      timeStart,
      timeEnd,
      platform,
      vestId
    ) {
      this.$axios({
        url:
          "/order/service/statisticsHeaderList?rows=" +
          rows +
          "&roleName=" +
          roleName +
          "&goupId=" +
          goupId +
          "&people=" +
          people +
          "&timeAll=0" +
          "&timeStart=" +
          timeStart +
          "&timeEnd=" +
          timeEnd +
          "&platform=" +
          platform +
          "&vestId=" +
          vestId,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.code == 0 || res.data.message == "调用成功") {
            const totalData = res.data.data; // 赋值
            this.allOverviewVoObj = totalData.allOverviewVo; // 总览
            (this.workOrderEventStatisticsVoObj =
              totalData.workOrderEventStatisticsVo), // 待处理数据总汇
              (this.btn[0].num =
                this.workOrderEventStatisticsVoObj.todayNewOrders); // 待处理订单(今日新订)
            this.btn[1].num = this.workOrderEventStatisticsVoObj.todayCheckIn; // 待处理订单(今日入住)
            this.btnCurThingList[0].num =
              this.workOrderEventStatisticsVoObj.customerOrder; // 待处理事件(客诉订单)
            this.btnCurThingList[1].num =
              this.workOrderEventStatisticsVoObj.pendingDownHotel; // 待处理事件(待下线酒店)
            this.workBenOrderVoLIst = totalData.workBenOrderVo; // 待处理订单
            this.workBenEventVoLIst = totalData.workBenEventVo; // 待处理事件
            this.starLevelArray = totalData.salesStar; // 销售星级
            this.saleChannelArray = totalData.salesChannel; // 销售渠道
            this.starLevelArray.forEach((item) => {
              let val = item.valuee.replace(/%/g, "");
              this.$set(item, "value", Number(val));
              this.$set(item, "name", item.keyy);
              delete item.keyy;
              delete item.valuee;
            });
            this.saleChannelArray.forEach((item) => {
              if (item.keyy == null) {
                item.keyy = "其他";
              }
              let val = item.valuee.replace(/%/g, "");
              this.$set(item, "value", Number(val));
              this.$set(item, "name", item.keyy);
              delete item.keyy;
              delete item.valuee;
            });
            //  console.log(totalData, "工作台头部数据");
            setTimeout(() => {
              this.refreshSaleLevelData(this.starLevelArray); // 绘制销售星级可视化图表
            }, 1000);
            setTimeout(() => {
              this.refreshSaleChannelData(this.saleChannelArray); // 绘制销售渠道可视化图表
            }, 1000);
          }
        })
        .catch((error) => {
          //  console.log(error, "请求失败的响应数据");
        });
    },
    // 待处理订单选项卡
    btnFn(index) {
      this.btnCur = index;
      this.$router.push({
        path: "/order",
        query: {
          activeIndex: 4,
          quickSelected: index + 1,
        },
      });
    },
    // 待处理事件切换选项选项卡
    btnCurThingFn(index) {
      this.btnCurThing = index;
      if (index == 1) {
        this.$router.push({
          path: "/hotel",
          query: {
            activeIndex: 3,
          },
        });
      } else if (index == 0) {
        this.$router.push({
          path: "/order",
          query: {
            activeIndex: 2,
          },
        });
      }
    },
    // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.form.group = "";
        this.person = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.person = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
      //  console.log(this.ajaxGroupId, "组id");
    },
    // 时间选择
    checkDate() {
      if (this.form.timeList == null) {
        this.form.timeList = [];
        this.form.timeList[0] = "";
        this.form.timeList[1] = "";
      }
      //  console.log(this.form.timeList, "时间");
    },
    // 渠道选择
    async checkChannel(item) {
      let { code, data } = await getVestid_api({
        platform: item,
      });
      if (code == 0) {
        this.form.vest = "";
        this.vestOPtions = [];
        this.vestOPtions = data;
      }
    },
    // 获取选择对应的马甲
    checkVest(item) {
      this.vestOPtions = []; // 初始化为空
      this.form.vest = item; // 赋值
      this.ajaxVestId = item;
      //  console.log(item, "获取马甲");
    },
    // 点击查询的事件处理函数
    search() {
      this.getWorkbenchHeaderDataFn(
        999999,
        this.form.job,
        this.ajaxGroupId == undefined ? "" : this.ajaxGroupId,
        this.person,
        this.form.timeList[0],
        this.form.timeList[1],
        this.form.channel,
        this.form.vest
      ); // 调用获取头部数据函数
    },
    // 点击"清空"按钮的事件处理函数
    clear() {
      this.form = {}; // 表单数据为空
      this.ajaxVestId = "";
      this.ajaxGroupId = "";
      this.ajaxPersonId = "";
      this.getWorkbenchHeaderDataFn("", "", "", "", "", "", "", ""); // 初始化调用获取工作台头部分列表数据
    },
    // 刷新数据 销售星级分布chart 绘制
    refreshSaleLevelData(data) {
      var myChart = this.$echarts.init(document.getElementById("starChart"));
      var options = {
        title: {
          text: "销售星级分布",
          left: "center",
        },
        color: ["#5473E8", "#FFB443"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>占比 : ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "星级比例",
            type: "pie",
            radius: "50%",
            data,
            labelLine: {
              length: 30,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 绘制图表
      myChart.setOption(options);
    },
    // 刷新数据 销售星级分布chart 绘制
    refreshSaleChannelData(data) {
      var myChart = this.$echarts.init(document.getElementById("channelChart"));
      var options = {
        title: {
          text: "销售渠道分布",
          left: "center",
        },
        normal: {
          formatter: "{b}:{c}" + "\n\r" + "({d}%)",
          show: true,
          position: "left",
        },
        color: ["#5473E8", "#FFD28F", "#FFC671", "#FFB443", "#A5B7F4"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>占比： ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "渠道分布比例",
            type: "pie",
            radius: "50%",
            data,
            labelLine: {
              length: 30,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 绘制图表
      myChart.setOption(options);
    },

    // 表格查询
    tableSearch() {
      //   console.log("表格查询");
      this.getTableList();
    },
    // 表格数据导出
    exportData() {
      //  console.log("表格导出");
      // if(this.tableBtnCur==0){
      //   this.Url=
      //    "http://47.108.228.93:8080/order/service/getSaleDataExport?" +
      //    "dateType" +this.tableSearchDate.dateType
      // }
    },
    // 表格 选择时间
    checkTableDate() {
      //  console.log(this.tableTimeList, "表格时间选择");
    },

    // 表格按钮切换
    changeTable(index) {
      this.tableBtnCur = index;
      this.tableGroupOptions = [];
      this.tableSearchDate.goupId = "";
      this.tableSearchDate.people = "";
      this.getTableOption(index);
      this.getTableList();
    },
    //获取列表组别
    async getTableOption(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        //   console.log(data);
        this.tableGroupOptions = data;
      }
    },
    //切换组别
    changeGroup(val) {
      this.tableSearchDate.people = "";
      this.tablePersonOptions = [];
      // console.log(this.tableGroupOptions);
      if (val) {
        this.tableGroupOptions.forEach((item) => {
          if (item.keyy == val) {
            this.tablePersonOptions = item.list;
          }
        });
      }
    },
    getTableList() {
      this.tableSearchDate.dateType = this.tableSearchDateType;
      if (this.tableSearchDate.dateType == "所有日期") {
        this.tableSearchDate.dateType = 1;
      }
      this.tableSearchDate.startDate = this.initDate(this.tableTimeList[0]);
      this.tableSearchDate.endDate = this.initDate(this.tableTimeList[1]);
      if (this.tableBtnCur == 0) {
        this.getSaleDataOne();
      } else if (this.tableBtnCur == 1) {
        this.getOperateDataOne();
      }
    },
    //获取销售的统计列表
    async getSaleDataOne() {
      if (this.tableSearchDate.people == "") {
        this.tableSearchDate.iSall = 1;
      } else {
        this.tableSearchDate.iSall = "";
      }
      let { code, data } = await getSaleDataOne_api(this.tableSearchDate);
      if (code == 0) {
        if (this.tableSearchDate.people == "") {
          this.tableData = data;
        } else {
          this.tableData = data[0].getSaleDataOneVOList;
        }
      }
    },
    //获取运营的统计列表
    async getOperateDataOne() {
      if (this.tableSearchDate.people == "") {
        this.tableSearchDate.iSall = 1;
      } else {
        this.tableSearchDate.iSall = "";
      }
      let { code, data } = await getOperateDataOne_api(this.tableSearchDate);
      if (code == 0) {
        if (this.tableSearchDate.people == "") {
          this.tableData = data;
        } else {
          this.tableData = data[0].getSaleDataOneVOList;
        }
      }
    },

    openTargetDia() {
      if (this.tableBtnCur == 0) {
        this.$refs.setsalestarget.open();
      } else if (this.tableBtnCur == 1) {
        this.$refs.setoperatetarget.open();
      }
    },
    getTableSearchPeople(val) {
      //  console.log(val);
    },
    gotoOrder() {
      this.$router.push({
        path: "/order",
      });
    },
    gotoOrderFour() {
      this.$router.push({
        path: "/order",
        query: {
          activeIndex: 4,
        },
      });
    },
    gotoOrderFourForId(orderSn) {
      this.$router.push({
        path: "/order",
        query: {
          activeIndex: 4,
          orderSn: orderSn,
        },
      });
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    //获取本月第一天
    getCurrentMonthFirst() {
      let date = new Date();
      date.setDate(1);
      let month = parseInt(date.getMonth() + 1);
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return date.getFullYear() + "-" + month + "-" + day;
    },
    //本月最后一天
    getCurrentMonthLast() {
      let date = new Date();

      let currentMonth = date.getMonth();

      let nextMonth = ++currentMonth;

      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);

      let oneDay = 1000 * 60 * 60 * 24;

      let lastTime = new Date(nextMonthFirstDay - oneDay);

      let month = parseInt(lastTime.getMonth() + 1);

      let day = lastTime.getDate();

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return date.getFullYear() + "-" + month + "-" + day;
    },
    getDetailDate() {
      let date = new Date();
      let year1 = date.getFullYear();
      let year2 = date.getFullYear();
      let month1 = date.getMonth() + 1;
      let month2 = date.getMonth() + 1;
      let day = date.getDate();
      if (day < 26) {
        if (month2 == 1) {
          year1 = year1 - 1;
          let arritem1 = year1 + "-12-26";
          let arritem2 = year2 + "-1-25";
          return [arritem1, arritem2];
        } else {
          month1 = month1 - 1;
          if (month1 < 10) {
            month1 = "0" + month1;
          }
          if (month2 < 10) {
            month2 = "0" + month2;
          }
          let arritem1 = year1 + "-" + month1 + "-26";
          let arritem2 = year2 + "-" + month2 + "-25";
          return [arritem1, arritem2];
        }
      } else {
        if (month2 == 12) {
          year2 = year2 + 1;
          let arritem1 = year1 + "-12-26";
          let arritem2 = year2 + "-1-25";
          return [arritem1, arritem2];
        }else{
          month2=month2+1
           let arritem1 = year1 + "-" + month1 + "-26";
          let arritem2 = year2 + "-" + month2 + "-25";
          return [arritem1, arritem2];

        }
      }
    },

    //排序
    sortTable(column) {
      //排序方式
      // if(column.prop=="orderName"){
      //   this.tableSearchDate.sortType=1
      // }
      this.tableSearchDate.sortType = column.prop;
      //升序还是降序
      this.tableSearchDate.orderDirection =
        column.order === "descending" ? "desc" : "asc";
      this.getTableList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-table thead tr:first {
  background: #ebf1fa !important;
}
/deep/.el-range-input {
  background: #f6f8fb !important;
}
/deep/.el-form {
  padding: 12px 0px !important;
}
/deep/.el-input__icon {
  display: flex;
  align-items: center;
}
/deep/.el-range-separator {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-input__inner {
  width: 98px;
  height: 28px !important;
  background: #f6f8fb !important;
}
.workbench {
  /deep/.el-form {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin: 0 10px;
    }
  }
  .handle {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .hand {
      width: 48%;
      height: 400px;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
      border-radius: 10px;
      padding: 5px 10px;
      box-sizing: border-box;
      .head {
        .big {
          font-weight: bold;
          font-size: 18px;
          .num {
            color: red;
          }
        }
        .btn {
          padding: 10px;
          box-sizing: border-box;
          .el-button.active {
            background: #256def;
            color: #fff;
          }
        }
        .all {
          color: #256def;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .orderContent {
        // 待处理订单
        overflow-y: scroll;
        height: 338px;
        .content {
          margin: 0 12px;
          border-bottom: 1px solid #eee;
          .hotelName {
            padding: 5px 0;
            box-sizing: border-box;
            font-size: 16px;
            .hotel {
              font-weight: bold;
              display: flex;
              align-items: center;
              .icon {
                width: 3px;
                height: 12px;
                background: #33b93f;
                border-radius: 2px;
                margin-right: 4px;
              }
            }
            .phone {
              color: #333333;
            }
            .status {
              span {
                color: #f7a12e;
              }
            }
          }
          .msg {
            display: flex;
            align-items: center;
            .room {
              color: #0099cc;
              margin-left: 10px;
              font-size: 14px;
            }
          }
          .time {
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            .long {
              color: #acacac;
              display: flex;
              align-items: center;
              margin: 0 5px;
              div {
                width: 30px;
                height: 16px;
                border: 1px solid #acacac;
                border-radius: 8px;
                color: #256def;
                text-align: center;
                vertical-align: middle;
              }
            }
          }
        }
        // 待处理事件
        .thing {
          margin: 0 12px;
          height: 130px;
          width: 96%;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: flex-start;
          .left {
            flex: 1;
            .wrap {
              width: 100%;
              height: 30px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .info {
                width: 70px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #38455d;
              }
              .infoText {
                flex: 1;
                margin-left: 10px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #102647;
              }
            }
          }
          .right {
            width: 70px;
            height: 100%;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #256def;
            padding-top: 10px;
            box-sizing: border-box;
            cursor: pointer;
          }
        }
      }
    }
  }
  // 总览
  .overview {
    width: 1200px;
    height: 220px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    border-radius: 10px;
    margin-top: 20px;
    .top {
      width: 100%;
      height: 57px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .wrap {
      width: 100%;
      padding: 0px 20px;
      box-sizing: border-box;
      height: 122px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 98%;
        height: 120px;
        margin: 0px 10px;
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        .itemTop {
          height: 40px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .itemTopLeft {
            flex: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .itemBottom {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333333;
        }
      }
    }
  }
  // echarts
  .chart {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .chartStar {
      width: 48%;
      height: 300px;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 10px;
    }
    .chartChannel {
      width: 48%;
      height: 300px;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  // 表格数据
  .tableData {
    width: 100%;
    height: 860px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 20px;
    .first {
      padding: 20px 0px 0px 20px;
      box-sizing: border-box;
      .tableBtn {
        &.active {
          background: #256def;
          color: #fff;
        }
      }
    }
  }
}

//公共样式
.pd {
  padding-bottom: 10px;
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-icon-caret-bottom {
  font-size: 16px;
  margin-top: 2px;
}
.el-icon-caret-top {
  font-size: 16px;
}
</style>