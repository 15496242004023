<template>
  <div>
    <el-dialog
      title="设置目标值"
      :visible.sync="dialogFormVisible"
      width="957px"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <el-table :data="tableData" stripe style="width: 800px; margin: 0 auto">
        <el-table-column label="运营" prop="name"></el-table-column>
          <el-table-column label="夜间量目标值">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.nightTarget"></el-input>
          </template>
        </el-table-column>
          <el-table-column label="业绩目标值">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.performanceTarget"></el-input>
          </template>
        </el-table-column>
      
        <el-table-column label="确认前满房率目标值">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.fullRoomRateTarget"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSubTargetList_api } from "../../../apis/workbench";
import { batchSetSubTarget_api } from "../../../apis/workbench";
export default {
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
    };
  },
  methods: {
    async open() {
      //   this.getSubTargetList();
      let { code, data } = await getSubTargetList_api();
      if (code == 0) {
        console.log(data.targetInfos);
        if (data.targetInfos.length==0) {
          this.$message.warning("没有可设置的人员");
        } else {
          this.tableData = data.targetInfos;
          this.dialogFormVisible = true;
        }
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
  async  handEditData() {
      let {code,data}=await batchSetSubTarget_api(this.tableData)
      if(code==0){
         this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
      }
     
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

