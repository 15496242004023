// 工作台模块的接口api
import req from "../utils/req"

// 获取工作台头部数据(The use of apis comes from ak)
export function statisticsHeaderList_api(params) {
    return req.get("/order/service/statisticsHeaderList", params)
}

// 根据选择马甲获取马甲id(The use of apis comes from ak)
export function getVestid_api(params) {
    return req.get("/order/service/getVestid", params)
}

//根据角色获取组与组员|(工作台)(The use of apis comes from ak)
export function getGroups_api(param) {
    return req.get("/otaHotel/horseGroup/getGroups", param)
}
//获取销售的统计列表
export function getSaleDataOne_api(param) {
    return req.get("/order/service/getSaleDataOne", param)
}
//获取运营的统计列表
export function getOperateDataOne_api(param) {
    return req.get("/order/service/getOperateDataOne", param)
}
//获取目标值的基础列表
export function getSubTargetList_api(param) {
    return req.get("/otaHotel/manager/getSubTargetList", param)
}
//设置目标值
export function batchSetSubTarget_api(param) {
    return req.postlg("/otaHotel/manager/batchSetSubTarget", param)
}


